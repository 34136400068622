import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip);
function Pi(props) {
  const data = {
    labels: props.labels,
    datasets: [
      {
        label: "Hours Per Company",
        data: props.data,
        backgroundColor: [
          "#8b0e0e",
          "#0cb14b",
          "#6D2570",
          "#F17F21",
          "#000000",
        ],
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="donut-breakdown">
      <Doughnut datasetIdKey="id" data={data} />
    </div>
  );
}

export default Pi;
