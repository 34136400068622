import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import userService from "../../services/user.service";
import authService from "../../services/auth.service";
import requestService from "../../services/request.service";
import Sidebar from "../../components/Sidebar";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
import { Table, Button } from "react-bootstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const FadeIn = styled.div`
  animation: 0.5s ${keyframes`${fadeIn}`};
`;

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

function exportToCSV(csvData, fileName) {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}

function ViewPtoRequest() {
  let userId = authService.getCurrentUser().id;
  let role = authService.getCurrentUser().roles;
  const navigate = useNavigate();
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    requestService.getUnseenPtoRequests().then((res) => {
      console.log(res.data);
      setRequests(res.data);
    });
  }, []);

  function handleApprove(id) {
    console.log("approving: ", id);
    requestService.approvePtoRequest(id).then((res) => {
      console.log(res.data);
      const exportData = [res.data];
      exportToCSV(exportData, `${res.data.user}-pto-request-approved`);
      window.location.reload();
    });
  }

  function handleDeny(id) {
    console.log("denying: " + id);
    requestService.denyPtoRequest(id).then((res) => {
      if (res.data === "SUCCESS") {
        window.location.reload();
      }
    });
  }

  return (
    <>
      {role.includes("ROLE_MODERATOR") || role.includes("ROLE_ADMIN") ? (
        <div className="flex">
          <Sidebar />
          <div className="request-container">
            <header className="header">Approve Sick Leave</header>
            <div className="flex align-items-center">
              <FadeIn>
                <Table
                  striped
                  bordered
                  responsive-md
                  hover
                  style={{ marginTop: 100 }}
                  id="myTable"
                >
                  <thead>
                    <tr className="bg-dark white" style={{ color: "white" }}>
                      <th>#</th>
                      <th>Employee</th>
                      <th>Amount Available</th>
                      <th>Amount Requested</th>
                      <th>Reason</th>
                      <th>Approve</th>
                      <th>Deny</th>
                    </tr>
                  </thead>
                  <tbody>
                    {requests.map((item, i) => {
                      // console.log("item" + JSON.stringify(reports[item]));
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item.user_name}</td>
                          <td>{item.sick_time.total_time}</td>
                          <td>{item.amount}</td>
                          <td>{item.reason}</td>
                          <td>
                            <button
                              className="mt-2 ms-btn purp-btn"
                              onClick={() => handleApprove(item.id)}
                            >
                              Approve
                            </button>
                          </td>
                          <td>
                            <button
                              className="mt-2 ms-btn purp-btn"
                              onClick={() => handleDeny(item.id)}
                            >
                              Deny
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {!requests.length && <h2>No Requests</h2>}
              </FadeIn>
            </div>
          </div>
        </div>
      ) : (
        <h1>You do not have access to this page</h1>
      )}
    </>
  );
}

export default ViewPtoRequest;
