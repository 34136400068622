import React from "react";
import FontAwesome from "react-fontawesome";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { fadeInDown, fadeInUp } from "react-animations";
import authService from "../services/auth.service";
const FadeUp = styled.div`
  animation: 0.5s ${keyframes`${fadeInUp}`};
`;
const FadeDown = styled.div`
  animation: 0.5s ${keyframes`${fadeInDown}`};
`;
function ProjectSelect() {
  const roles = authService.getCurrentUser().roles;
  const navigate = useNavigate();
  const handleRoute = (path) => {
    navigate(path);
  };

  return (
    <div className={`project-overview `}>
      <FadeDown>
        <div className="p-decision new-project-container">
          <div>
            <h1>
              {roles.includes("ROLE_ADMIN")
                ? "Create a New Project"
                : "Request a New Project"}
            </h1>
            <button
              onClick={() => handleRoute("/projects-create-new")}
              className="new-proj-btn"
            >
              {roles.includes("ROLE_ADMIN") ? "create" : "request"}
            </button>
          </div>
          <FontAwesome
            className="nav-icon"
            name="plus"
            size="4x"
            style={{ textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)" }}
          />
        </div>
      </FadeDown>
      <FadeUp>
        <div className="p-decision view-projects-container">
          <div>
            <h1>View Assigned Projects</h1>
            <button
              onClick={() => handleRoute("/projects-view")}
              className="view-proj-btn"
            >
              view
            </button>
          </div>
          <FontAwesome
            className="nav-icon"
            name="clipboard-list"
            size="4x"
            style={{ textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)" }}
          />
        </div>
      </FadeUp>
      {authService.getCurrentUser().roles.includes("ROLE_ADMIN") ? (
        <FadeUp>
          <div className="p-decision ">
            <div>
              <h1>Create a Client</h1>
              <button
                onClick={() => handleRoute("/create-client")}
                className="view-proj-btn"
              >
                Create
              </button>
            </div>
            <FontAwesome
              className="nav-icon"
              name="user"
              size="4x"
              style={{ textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)" }}
            />
          </div>
        </FadeUp>
      ) : null}
    </div>
  );
}

export default ProjectSelect;
