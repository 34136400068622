import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";

import authService from "../../services/auth.service";
import Select from "react-select";
import projectService from "../../services/project.service";
import { Table } from "react-bootstrap";
function Billing() {
  const user = authService.getCurrentUser();
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    projectService.getCompletedProjects({ uid: user.id }).then(function (res) {
      let projects = res.data.projects;

      setProjects(projects);
    });
  }, []);

  return (
    <div>
      <div className="flex">
        <Sidebar />

        <div className="w-100 completed-projects-container">
          <header>
            <h1 className="header">Completed Projects</h1>
          </header>
          <div className="table-container">
            <Table striped bordered>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Project Name</th>

                  <th>Billing Status</th>
                  <th className="payment">Labor</th>
                  <th className="payment">Reimbursed</th>
                </tr>
              </thead>
              <tbody>
                {projects.map((project, index) => {
                  if (project.submitted_for_billing) {
                    return (
                      <tr>
                        <td>{project.id}</td>
                        <td>{project.project_name}</td>
                        <td>{project.billing_status}</td>
                        <td>${project.labor_expense}</td>
                        <td>${project.reimbursed_expense}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Billing;
