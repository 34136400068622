import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthService from "../services/auth.service";
import authService from "../services/auth.service";

function Login() {
  const [error, setError] = useState("");
  const verifyUser = (data) => {
    AuthService.login(data.email, data.password).then(
      () => {
        window.location.href = "/";
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setError(resMessage);
      }
    );
  };
  useEffect(() => {
    let test = authService.getCurrentUser();
    if (test) {
      window.location.href = "/";
    }
  }, []);

  const initialValues = {
    email: "",
    password: "",
  };
  const loginSchema = Yup.object().shape({
    email: Yup.string().email("invalid email").required("email is required"),
    password: Yup.string().required("password required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      setError("");
      verifyUser(values);
    },
  });
  return (
    <div className="login-container">
      <h1 className="login-welcome">Welcome Back</h1>

      <form onSubmit={formik.handleSubmit} className="login-form">
        <input
          id="email"
          name="email"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          placeholder="email@propervillainsllc.com"
          className="login-input"
        ></input>
        <input
          id="password"
          name="password"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          placeholder="************"
          className="login-input"
        ></input>
        <button type="submit" className="login-btn">
          login
        </button>
        {error ? <div className="login-error">{error}</div> : null}
      </form>
    </div>
  );
}

export default Login;
