import axios from "axios";
import authHeader from "./auth-header";

const API_URL = window.location.href.includes("local")
  ? "http://localhost:8080/api/"
  : window.location.href.includes("proper")
  ? "https://omis.herokuapp.com/api/"
  : window.location.href.includes("omisbk")
  ? "https://myomis-archive.herokuapp.com/api/"
  : "https://myomis.herokuapp.com/api/";

class RequestService {
  submitRequest(data) {
    return axios.post(API_URL + "requests/new", data, {
      headers: authHeader(),
    });
  }
  getAll(uid) {
    return axios.post(API_URL + "requests/all", uid, {
      headers: authHeader(),
    });
  }
  updateStatus(data) {
    return axios.post(API_URL + "requests/update", data, {
      headers: authHeader(),
    });
  }
  getUnreadCount(user) {
    return axios.post(API_URL + "requests/unread", user, {
      headers: authHeader(),
    });
  }
  requestPto(amount, reason, userId) {
    const data = { amount, reason, userId };

    return axios.post(API_URL + "requests/request-pto", data, {
      headers: authHeader(),
    });
  }
  getUnseenPtoRequests() {
    return axios.get(API_URL + "requests/pto/all-unseen", {
      headers: authHeader(),
    });
  }
  approvePtoRequest(id) {
    const data = { id: id };
    return axios.post(API_URL + "requests/pto/approve", data, {
      headers: authHeader(),
    });
  }
  denyPtoRequest(id) {
    const data = { id: id };
    return axios.post(API_URL + "requests/pto/deny", data, {
      headers: authHeader(),
    });
  }
}

export default new RequestService();
