import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Select from "react-select";
import projectService from "../../services/project.service";
import userService from "../../services/user.service";
import authService from "../../services/auth.service";
import { Table } from "react-bootstrap";
import "react-day-picker/lib/style.css";
import { titleCase, getSelect } from "../../services/helper";
import timeService from "../../services/time.service";
function NewSheet() {
  const navigate = useNavigate();
  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,

        backgroundColor: isFocused ? "var(--tcMain)" : null,
        color: isFocused ? "white" : "#333333",
      };
    },
  };
  const selectDay = getSelect("day");
  let [user, setUser] = useState(authService.getCurrentUser());

  const [date, setDate] = useState("");
  const [pto, setPto] = useState(0);
  const [holdDay, setHoldDay] = useState("");
  const [holdProjName, setHoldProjName] = useState("");
  const [holdProjID, setHoldProjID] = useState("");
  const [holdHours, setHoldHours] = useState(0);
  const [holdCompany, setHoldCompany] = useState("");
  const [holdStatus, setHoldStatus] = useState("");
  const [projects, setProjects] = useState([]);
  const [daySelected, setDaySelected] = useState("Monday");
  const [holdMilestone, setHoldMilestone] = useState("");
  const [holdMilestoneID, setHoldMilestoneID] = useState("");
  const [milestones, setMilestones] = useState([]);
  const [times, setTimes] = useState([]);
  const [dayHours, setDayHours] = useState(0);
  const [error, setError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);

  useEffect(() => {
    if (holdHours % 0.25 == 0 && holdHours > 0) setIsDisabled(false);
    else setIsDisabled(true);
  });

  useEffect(() => {
    projectService.getProjects({ id: user.id }).then((res) => {
      let data = res.data;

      let finalArr = [];
      data.map((item) => {
        if (item.status != "completed") {
          finalArr.push({
            label: item.project_name,
            value: item.id,
            company: item.company,
          });
        }
      });
      setProjects(finalArr);
    });
  }, []);

  // useEffect(() => {
  //   userService.getPto(user.id).then((res) => {
  //     console.log("PTO: " + res.data.pto);
  //     setPto(res.data.pto);
  //   });
  // }, [user]);

  const handleProjectChange = (e) => {
    setHoldProjID(e.value);
    setHoldProjName(e.label);
    let tempMsArr = [];
    projectService.getMilestones({ pid: e.value }).then((res) => {
      res.data.forEach((ms) => {
        if (ms.company && ms.status != "Complete") {
          tempMsArr.push({
            label: ms.milestone_name,
            value: ms.milestone_name,
            id: ms.id,
            company: ms.company,
          });
        }
      });
      setMilestones(tempMsArr);
    });
  };
  const addTime = () => {
    let quarterHour = /^\d?(\.(25|50?|75|0|00))?$/gm.test(holdHours);
    if (
      holdDay &&
      holdProjName &&
      holdHours &&
      holdCompany &&
      holdMilestone &&
      holdStatus
    ) {
      if (quarterHour) {
        projectService
          .updateMs({
            msId: [holdMilestoneID],
            field: "status",
            newValue: holdStatus,
          })
          .then(() => {
            console.log("updated status");
          });
        let old = [...times];
        old.push({
          company: holdCompany,
          project: holdProjID,
          hours: holdHours,
          day: holdDay,
          project_name: holdProjName,
          milestone_name: holdMilestone,
          milsetone_id: holdMilestoneID,
        });
        setTimes(old);
        setDaySelected(holdDay);
        setHoldCompany("");
        setHoldDay("");
        setHoldProjID(0);
        setHoldHours(0);
        setHoldProjName("");
        setHoldMilestone("");
        setHoldMilestoneID("");
        handleSubmit(null, false);
      } else {
        triggerError("Invalid time");
      }
    } else {
      triggerError("Please fill all fields before submitting a new time");
    }
  };
  const triggerError = (message) => {
    setError(message);
    setTimeout(() => {
      setError("");
    }, 5000);
  };
  const handleSubmit = (e, reroute) => {
    timeService
      .submitTime({
        uid: user.id,
        user_name: user.name,

        week_ending: date,
        times: times,
      })
      .then(function (res) {
        setHoldStatus("");
        if (reroute) {
          navigate("/time-sheets");
        }
      })
      .catch((err) => {
        let { message } = err.response.data;
        setError(message);
      });
  };
  const handleDate = (e) => {
    setError("");
    const isSunday = new Date(e.target.value).getDay();
    if (isSunday == 6) {
      setDate(e.target.value);
    } else {
      setError("Please select a valid date");
      setDate("");
    }
  };
  useEffect(() => {
    if (times.length > 0) {
      let tempTimes = [...times];
      let dayTotal = 0;
      tempTimes = tempTimes.filter((t) => t.day == daySelected);
      if (tempTimes.length > 0) {
        tempTimes.forEach((t) => {
          dayTotal += parseFloat(t.hours);
        });
      }
      setDayHours(dayTotal);
    }
  }, [daySelected, times]);

  const validateHours = (hours) => {
    if (hours % 0.25 !== 0 || hours < 0) {
      setDisableSaveBtn(true);
    } else setDisableSaveBtn(false);
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="timesheet-overview">
        <header className="d-flex align-items-center justify-content-between ">
          <h1 className="header">New Sheet</h1>
          <button
            onClick={(e) => handleSubmit(e, true)}
            className="mt-0 purp-btn ts-save"
            disabled={disableSaveBtn}
          >
            save
          </button>
        </header>
        <div className="total-hrs-container">
          <h2 className="sub-header">
            Current Total Hours:{" "}
            {times.reduce(function (acc, obj) {
              return acc + parseFloat(obj.hours);
            }, 0) || 0}
          </h2>
          {/* <h2 className="sub-header">Available PTO: {pto}</h2> */}
        </div>
        {error ? (
          <div className="ts-errors">
            <span className="red">*</span> {error}
          </div>
        ) : null}
        <div className="week-ending-group">
          <label>Week Ending</label>
          <input
            className="fancy-input"
            required
            value={date}
            onChange={handleDate}
            type="date"
          ></input>
        </div>
        <div className="new-entry">
          <div className="new-entry-group third-container">
            <label className="ts-label">Day of the Week</label>
            <Select
              value={{ label: holdDay, value: holdDay }}
              onChange={(e) => setHoldDay(e.value)}
              styles={customStyles}
              className="new-entry-select"
              options={selectDay}
            />
          </div>
          <div className="new-entry-group">
            <label className="ts-label">Project</label>

            <Select
              onChange={handleProjectChange}
              styles={customStyles}
              className="new-entry-select"
              options={projects}
              value={{ label: holdProjName, value: holdProjName }}
            />
          </div>
          <div className="new-entry-group">
            <label className="ts-label">Milestone</label>
            <Select
              options={milestones}
              styles={customStyles}
              value={{ label: holdMilestone, value: holdMilestone }}
              onChange={(e) => {
                setHoldMilestoneID(e.id);
                setHoldMilestone(e.value);
                setHoldCompany(e.company);
              }}
            />
          </div>
          <div className="new-entry-group">
            <label className="ts-label">Status</label>
            <Select
              options={getSelect("status")}
              styles={customStyles}
              value={{ label: holdStatus, value: holdStatus }}
              onChange={(e) => {
                setHoldStatus(e.value);
              }}
            />
          </div>
          <div className="new-entry-group w-20">
            <label className="ts-label">Hours</label>

            <input
              value={holdHours}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !isDisabled) {
                  addTime();
                }
              }}
              onChange={(e) => setHoldHours(e.target.value)}
              className=" w-100 fancy-input"
              type="number"
            ></input>
          </div>
          <div className="w-20 justify-content-center d-flex align-items-end new-entry-group text-center">
            <button
              onClick={addTime}
              className="purp-btn new-entry-submit"
              disabled={isDisabled}
            >
              add
            </button>
          </div>
        </div>
        <h2 className="day-hours sub-header">
          {daySelected} total hours: {dayHours == NaN ? 0 : dayHours}
        </h2>
        <div className="tab-switcher-container">
          <div className="tab-switcher-head">
            <div
              onClick={() => setDaySelected("Monday")}
              className={`${
                daySelected === "Monday" ? "active-tab" : null
              } tab-head-item`}
            >
              Monday
            </div>
            <div
              onClick={() => setDaySelected("Tuesday")}
              className={`${
                daySelected === "Tuesday" ? "active-tab" : null
              } tab-head-item`}
            >
              Tuesday
            </div>
            <div
              onClick={() => setDaySelected("Wednesday")}
              className={`${
                daySelected === "Wednesday" ? "active-tab" : null
              } tab-head-item`}
            >
              Wednesday
            </div>
            <div
              onClick={() => setDaySelected("Thursday")}
              className={`${
                daySelected === "Thursday" ? "active-tab" : null
              } tab-head-item`}
            >
              Thursday
            </div>
            <div
              onClick={() => setDaySelected("Friday")}
              className={`${
                daySelected === "Friday" ? "active-tab" : null
              } tab-head-item`}
            >
              Friday
            </div>
            <div
              onClick={() => setDaySelected("Saturday")}
              className={`${
                daySelected === "Saturday" ? "active-tab" : null
              } tab-head-item`}
            >
              Saturday
            </div>
            <div
              onClick={() => setDaySelected("Sunday")}
              className={`${
                daySelected === "Sunday" ? "active-tab" : null
              } tab-head-item`}
            >
              Sunday
            </div>
            <div
              onClick={() => setDaySelected("Volunteer")}
              className={`${
                daySelected === "Volunteer" ? "active-tab" : null
              } tab-head-item`}
            >
              Volunteer
            </div>
          </div>

          <div className="tab-content-container">
            <div className="tab-switcher-content">
              <Table responsive="sm">
                <thead>
                  <tr>
                    <th style={{ width: "15%" }}>Day</th>
                    <th>Project</th>
                    <th>Milestone</th>
                    <th style={{ width: "15%" }}>Hours</th>
                    <th style={{ width: "15%" }}>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {times.map((time, index) => {
                    if (time.day === daySelected) {
                      return (
                        <tr className="white" key={index}>
                          <td>
                            <Select
                              onChange={(e) => {
                                let newArr = [...times];
                                newArr[index].day = e.value;
                                setTimes(newArr);
                              }}
                              value={{
                                label: titleCase(time.day),
                                value: time.day,
                              }}
                              styles={customStyles}
                              options={selectDay}
                            />
                          </td>
                          <td valign="ceneter">
                            <p>{time.project_name}</p>
                          </td>
                          <td valign="center">
                            <p>{time.milestone_name}</p>
                          </td>
                          <td>
                            <input
                              onChange={(e) => {
                                validateHours(parseFloat(e.target.value));
                                let newArr = [...times];
                                newArr[index].hours = e.target.value;
                                setTimes(newArr);
                              }}
                              onKeyPress={(e) => {
                                if (e.code === "Minus") {
                                  e.preventDefault();
                                }
                              }}
                              className="fancy-input"
                              type="number"
                              defaultValue={time.hours}
                            ></input>
                          </td>
                          <td>
                            <button
                              onClick={() => {
                                setTimes(
                                  times.filter((time, i) => i !== index)
                                );
                              }}
                              className=" edit-btn"
                            >
                              delete
                            </button>
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewSheet;
