import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Milestones from "./Milestones";
import projectService from "../../services/project.service";
import EditExisting from "./EditExisting";
import CompletedMilestones from "./CompletedMilestones";
import Select from "react-select";
import { getSelect } from "../../services/helper";
import { ProgressBar } from "react-bootstrap";
import ProjectBreakdown from "./ProjectBreakdown";

function DeatiledView() {
  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "var(--tcMain)" : null,
        color: isFocused ? "white" : "#333333",
      };
    },
  };
  const { id } = useParams();
  const [view, switchView] = useState("activeMilestones");
  const [project, setProject] = useState("");
  const [msCount, setMsCount] = useState(0);
  const [msComplete, setMsComplete] = useState(0);
  const [statusOptions, setStatusOptions] = useState(getSelect("status"));
  useEffect(() => {
    if (!project || !msCount || !msComplete) {
      projectService.getProjectById(id).then((res) => {
        if (Array.isArray(res.data.project)) {
          setProject(res.data.project[0]);
        } else {
          setProject(res.data.project);
        }
      });
    }
  }, []);
  // console.log(view);
  useEffect(() => {
    projectService.getMilestones({ pid: id }).then((res) => {
      let complete = 0;
      let total = res.data.length;
      res.data.map((m) => {
        if (
          m.status.toLowerCase() == "completed" ||
          m.status.toLowerCase() == "complete"
        ) {
          complete++;
        }
      });
      if (total > 0 && project.status == "Not Started") {
        statusUpdate({ value: "In Progress" }, id);
      }
      if (total > 0 && total == complete) {
        statusUpdate({ value: "Complete" }, id);
      }
      setMsComplete(complete);
      setMsCount(total);
    });
  }, [view]);

  useEffect(() => {
    if (msCount == msComplete && msCount > 0) {
      setStatusOptions(getSelect("status"));
    } else {
      setStatusOptions(getSelect("status-nocomplete"));
    }
  }, [msCount, msComplete]);
  const statusUpdate = (e, id) => {
    let newStatus = e.value;
    projectService.updateStatus({ id, status: newStatus }).then(() => {});
    setProject((old) => ({ ...old, status: newStatus }));
  };
  return (
    <div className="flex">
      <Sidebar />
      <div className="w-100 inner-container">
        <header className="header pb-2">
          Project Details For {project ? project.project_name : ""}
        </header>
        <div className=" project-deets-overview pb-5">
          {project ? (
            <Select
              styles={customStyles}
              className="w-50 full-width-sm"
              onChange={(e) => {
                statusUpdate(e, project.id);
              }}
              value={{
                label: (
                  <div className="select-flex">
                    <span
                      className={
                        project.status.replace(/\s/g, "").toLowerCase() +
                        " circle"
                      }
                      dangerouslySetInnerHTML={{
                        __html: "&#9679",
                      }}
                    />
                    <span
                      className="boldish"
                      dangerouslySetInnerHTML={{
                        __html: project.status,
                      }}
                    />
                  </div>
                ),
                value: project.status,
              }}
              options={statusOptions}
            />
          ) : null}
          <div className="project-stats">
            <p>Total Milestones: {msCount}</p>
            <p>Completed Milestones: {msComplete}</p>
            <ProgressBar
              className="mt-2"
              animated
              now={parseInt((msComplete / parseInt(msCount)) * 100).toFixed(0)}
            />
          </div>
        </div>
        <div className=" tab-switcher-container d-flex justify-content-center">
          <div className="tab-switcher-head">
            <div
              onClick={() => switchView("activeMilestones")}
              className={`${
                view == "activeMilestones" ? "active-tab" : null
              } tab-head-item`}
            >
              Active Milestones
            </div>
            <div
              className={`${
                view == "completedMilestones" ? "active-tab" : null
              } tab-head-item`}
              onClick={() => switchView("completedMilestones")}
            >
              Completed Milestones
            </div>
            <div
              onClick={() => switchView("edit")}
              className={`${
                view == "edit" ? "active-tab" : null
              } tab-head-item`}
            >
              Edit Details
            </div>
            <div
              onClick={() => switchView("breakdown")}
              className={`${
                view == "breakdown" ? "active-tab" : null
              } tab-head-item`}
            >
              Breakdown
            </div>
          </div>
        </div>
        {view == "activeMilestones" ? (
          <Milestones id={id} />
        ) : view === "edit" ? (
          <EditExisting />
        ) : view == "completedMilestones" ? (
          <CompletedMilestones id={id} />
        ) : view == "breakdown" ? (
          <ProjectBreakdown project={project} />
        ) : null}
      </div>
    </div>
  );
}

export default DeatiledView;
