import React, { useEffect, useState } from "react";
import NavItem from "./NavItem";
import { useNavigate } from "react-router-dom";
import authService from "../services/auth.service";
import requestService from "../services/request.service";
import projectService from "../services/project.service";
import Logo3 from "./logo3.png";
import EvilGrandma from "./myomis-Evil.png";
import BeachVibes from "./OMIS-BeachVibes.png";
import Leopard from "./logo-lp.png";
import MintChip from "./Omis-MintChip.png";
import WoodstockGrandma from "./Omis-Woodstock.png";
import SpookyGrandma from "./SpookyGrandma.png";
import { themeList } from "../services/helper";
import userService from "../services/user.service";
function Sidebar() {
  const mapObj = {
    evil: EvilGrandma,
    og: Logo3,
    beach: BeachVibes,
    lp: Leopard,
    mint: MintChip,
    woodstock: WoodstockGrandma,
    spooky: SpookyGrandma,
  };
  const possibleThemes = themeList();
  const [logo, setLogo] = useState(localStorage.getItem("logo"));
  const [unreadRequests, setUnreadRequests] = useState(0);
  const [unreadProjectRequests, setUnreadProjectRequests] = useState(0);
  const isAdmin = authService.getCurrentUser().roles.includes("ROLE_ADMIN")
    ? true
    : false;

  const user = authService.getCurrentUser();

  useEffect(() => {
    if (authService.getCurrentUser().roles.includes("ROLE_ADMIN")) {
      projectService.getRequestedCount().then((res) => {
        // console.log("Requested " + JSON.stringify(res));
        setUnreadProjectRequests(res.data.reqCount);
      });
    }
  }, []);

  useEffect(() => {
    if (authService.getCurrentUser().roles.includes("ROLE_ADMIN")) {
      requestService.getUnreadCount({ uid: user.id }).then(function (res) {
        setUnreadRequests(res.data.length);
      });
    }
    if (authService.getCurrentUser()) {
      userService
        .getThemeColor({ uid: authService.getCurrentUser().id })
        .then((res) => {
          let themeObj = possibleThemes.find(
            (u) => u.themeDbName === res.data.theme_color
          );
          if (themeObj.hasOwnProperty("alternateLogo")) {
            if (
              !localStorage.getItem("logo") ||
              localStorage.getItem("logo") != themeObj.alternateLogo
            ) {
              setLogo(themeObj.alternateLogo);
              localStorage.setItem("logo", themeObj.alternateLogo);
            }
          } else {
            localStorage.setItem("logo", "og");
          }
        });
    }
  }, []);
  const navigate = useNavigate();
  return (
    <nav className="sidebar">
      <div className="vert-sidebar">
        <img
          onClick={() => navigate("/")}
          className="nav-logo"
          src={mapObj[logo]}
          alt="logo"
        ></img>

        <NavItem link="/account" header="Account" icon="user" />
        <NavItem
          link="/projects"
          header="Projects"
          notification={
            unreadProjectRequests > 0 ? unreadProjectRequests.toString() : null
          }
          icon="clipboard-list"
        />
        {isAdmin && <NavItem link="/reports" header="Reports" icon="folder" />}
        {isAdmin && (
          <NavItem link="/new-reports" header="New Reports" icon="folder" />
        )}
        <NavItem link="/time-sheets" header="Time Sheets" icon="table" />
        {/* <NavItem link="/tasks" header="My Tasks" icon="list" /> */}
        <NavItem
          link="/requests"
          header="Submit Request"
          notification={unreadRequests > 0 ? unreadRequests.toString() : null}
          icon="arrow-alt-circle-up"
        />
        <NavItem
          link="/request-pto"
          header="Request Sick Leave"
          icon="calendar"
        />
        <NavItem
          link="/directory"
          header="User Directory"
          icon="address-book"
        />
        <NavItem link="/logout" header="Log Out" icon="door-open" />
        {/* <NavItem link="/calendar" header="Calendar" icon="calendar-alt" /> */}
      </div>
      <div className="horz-sidebar">
        <div className="nav-logo-mobile-container">
          <img
            onClick={() => navigate("/")}
            className="nav-logo-mobile pointer"
            src={mapObj[logo]}
            alt="mobile logo pointer"
          ></img>
        </div>
        <NavItem link="/account" header="Account" icon="user" />
        <NavItem link="/time-sheets" header="Sheets" icon="table" />
        <NavItem link="/projects" header="Projects" icon="clipboard-list" />
        {/* <NavItem link="/tasks" header="My Tasks" icon="list" /> */}
        <NavItem
          link="/requests"
          header="Requests"
          icon="arrow-alt-circle-up"
        />
      </div>
    </nav>
  );
}

export default Sidebar;
