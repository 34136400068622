import React from "react";

function CDInput(props) {
  return (
    <td>
      <input
        value={props.val}
        name={props.name}
        onChange={props.change}
        className="fancy-input"
      ></input>
    </td>
  );
}

export default CDInput;
