import React from "react";
import FontAwesome from "react-fontawesome";
function Th(props) {
  return (
    <th
      className={`pointer milestone-head ${props.class}`}
      style={{ width: "200px", minWidth: "150px" }}
      onClick={props.handleSorter}
    >
      {props.name}

      <FontAwesome className="sort-icon" name="sort" />
    </th>
  );
}

export default Th;
