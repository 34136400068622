import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Alert, Table } from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import Select from "react-select";
import timeService from "../../services/time.service";
import projectService from "../../services/project.service";
import authService from "../../services/auth.service";
import { titleCase, sortObjArray, getSelect } from "../../services/helper";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
import { validateYupSchema } from "formik";
import userService from "../../services/user.service";
const Fade = styled.div`
  animation: 0.5s ${keyframes`${fadeIn}`};
`;

function isQuarterHour(time) {
  if (time % 0.25 === 0 && time > 0) return true;
  else return false;
}

function EditSheet() {
  const daysConversion = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  };

  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,

        backgroundColor: isFocused ? "var(--tcMain)" : null,
        color: isFocused ? "white" : "#333333",
      };
    },
  };
  const selectDay = getSelect("day");
  const user = authService.getCurrentUser();
  let { week } = useParams();
  const [totalHours, setTotalHours] = useState();
  const [times, setTimes] = useState([]);
  // const [pto, setPto] = useState(0);
  const [holdDay, setHoldDay] = useState("");
  const [holdProjName, setHoldProjName] = useState("");
  const [holdProjID, setHoldProjID] = useState("");
  const [holdHours, setHoldHours] = useState(0);
  const [holdCompany, setHoldCompany] = useState("");
  const [holdMilestone, setHoldMilestone] = useState("");
  const [holdStatus, setHoldStatus] = useState("");
  const [holdMilestoneID, setHoldMilestoneID] = useState("");
  const [daySelected, setDaySelected] = useState("Monday");
  const [date, setDate] = useState(null);
  const [milestones, setMilestones] = useState([]);
  const [projects, setProjects] = useState([]);
  const [dayHours, setDayHours] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [infoMessage, setInfomessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [showAutoSave, setShowAutoSave] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (holdHours % 0.25 == 0 && holdHours > 0) setIsDisabled(false);
    else setIsDisabled(true);
  }, [holdHours]);

  useEffect(() => {
    console.log(times);
    if (times.length) {
      timeService
        .submitTime({
          user_name: user.name,
          uid: user.id,
          week_ending: date,
          times: times,
        })
        .then(function (res) {
          setHoldStatus("");
          setShowAutoSave(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [times]);

  useEffect(() => {
    let elem = document.getElementById("auto-save");
    if (!elem) return;
    elem.classList.add("show");
    setTimeout(() => {
      elem.classList.remove("show");
      elem.classList.add("disappear");
      setTimeout(() => setShowAutoSave(false), 1000);
    }, 2000);
  }, [showAutoSave]);

  const addTime = (e) => {
    let quarterHour = /^\d?(\.(25|50?|75|0|00))?$/gm.test(holdHours);
    console.log(quarterHour);

    if (holdDay && holdProjName && holdHours && holdCompany && holdMilestone && holdStatus) {
      if (quarterHour) {
        projectService
          .updateMs({
            msId: [holdMilestoneID],
            field: "status",
            newValue: holdStatus,
          })
          .then(() => {
            console.log("updated status");
          });
        let old = [...times];
        old.push({
          company: holdCompany,
          project: holdProjID,
          hours: holdHours,
          day: holdDay,
          project_name: holdProjName,
          milestone_name: holdMilestone,
          milsetone_id: holdMilestoneID,
        });
        setTimes(old);
        setDaySelected(holdDay);
        setHoldCompany("");
        setHoldDay("");
        setHoldStatus("");
        setHoldProjID(0);
        setHoldHours(0);
        setHoldProjName("");
        setHoldMilestone("");
        setHoldMilestoneID("");
      } else {
        triggerError("Invalid time");
      }
    } else {
      triggerError("Please fill all fields before submitting a new time");
    }
  };
  const triggerError = (message) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  };
  const handleProjectChange = (e) => {
    setHoldProjID(e.value);
    setHoldProjName(e.label);
    let tempMsArr = [];
    projectService.getMilestones({ pid: e.value }).then((res) => {
      res.data.forEach((ms) => {
        if (ms.company && ms.status != "Complete") {
          tempMsArr.push({
            label: ms.milestone_name,
            value: ms.milestone_name,
            id: ms.id,
            company: ms.company,
          });
        }
      });
      setMilestones(tempMsArr);
    });
  };

  const handleSubmit = (reroute) => {
    timeService
      .submitTime({
        user_name: user.name,
        uid: user.id,
        week_ending: date,
        times: times,
      })
      .then(function (res) {
        setHoldStatus("");
        if (reroute == true) {
          navigate("/time-sheets");
        }
      })
      .catch((err) => {});
  };
  const handleDate = (e) => {
    setDate(e.target.value);
  };
  useEffect(() => {
    timeService.getSheetByWeek({ uid: user.id, week: week }).then(function (res) {
      let hrs = 0;
      let totalArr = [];

      setDate(res.data[0].week_ending);
      res.data.map((item) => {
        hrs += parseFloat(item.hours);
        totalArr.push({
          company: item.company,
          project: item.project_id,
          project_name: item.project_name,
          milestone_name: item.milestone,
          milestone_options: [...milestones],
          hours: parseFloat(item.hours),
          day: titleCase(item.day),
          dayNum: daysConversion[item.day.toLowerCase()],
        });
      });

      totalArr.sort((a, b) => {
        return a.dayNum - b.dayNum;
      });

      setTimes(totalArr);
      setTotalHours(hrs);
      // userService.getPto(user.id).then((res) => {
      //   setPto(res.data.pto);
      // });
    });
  }, []);
  useEffect(() => {
    if (times.length > 0) {
      let tempTimes = [...times];
      let dayTotal = 0;
      tempTimes = tempTimes.filter((t) => t.day == daySelected);
      if (tempTimes.length > 0) {
        tempTimes.forEach((t) => {
          dayTotal += parseFloat(t.hours);
        });
      }
      setDayHours(dayTotal);
    }
  }, [daySelected, times]);
  useEffect(() => {
    projectService.getProjects({ id: user.id }).then((res) => {
      let data = res.data;
      let finalArr = [];
      data.map((item) => {
        if (item.status != "completed") {
          finalArr.push({
            label: item.project_name,
            value: item.id,
            company: item.company,
            milestones: item.milestones,
          });
        }
      });
      setProjects(finalArr);
    });
  }, []);

  const validateHours = (hours) => {
    if (hours % 0.25 !== 0 || hours < 0) {
      setDisableSaveBtn(true);
    } else setDisableSaveBtn(false);
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="timesheet-overview">
        <header className="d-flex align-items-center justify-content-between ">
          <h1 className="header">Edit Sheet</h1>
          {showAutoSave && (
            <span id="auto-save" className="auto-save">
              Auto-Saved
            </span>
          )}

          <button onClick={(e) => handleSubmit(true)} className="mt-0 purp-btn ts-save" disabled={disableSaveBtn}>
            save
          </button>
        </header>
        <div className="total-hrs-container">
          <h2 className="sub-header">
            Current Total Hours:{" "}
            {times.reduce(function (acc, obj) {
              return acc + parseFloat(obj.hours);
            }, 0) || 0}
          </h2>
          {/* <h2 className="sub-header">Available PTO: {pto}</h2> */}
        </div>
        <div className="week-ending-group">
          <label>Week Ending</label>
          <input className="fancy-input" value={date} onChange={handleDate} type="date" disabled></input>
        </div>
        {errorMessage ? (
          <Fade>
            <div className="w-25 error-box">
              <Alert variant="danger">{errorMessage}</Alert>
            </div>
          </Fade>
        ) : null}
        {infoMessage ? <Alert variant="info">{infoMessage}</Alert> : null}
        <div className="new-entry">
          <div className="new-entry-group third-container">
            <label className="ts-label">Day of the Week</label>
            <Select
              value={{ label: holdDay, value: holdDay }}
              onChange={(e) => setHoldDay(e.value)}
              styles={customStyles}
              className="new-entry-select"
              options={selectDay}
            />
          </div>
          <div className="new-entry-group">
            <label className="ts-label">Project</label>

            <Select
              onChange={handleProjectChange}
              value={{ label: holdProjName, value: holdProjName }}
              styles={customStyles}
              className="new-entry-select"
              options={projects}
            />
          </div>
          <div className="new-entry-group">
            <label className="ts-label">Milestone</label>
            <Select
              options={milestones}
              styles={customStyles}
              value={{ label: holdMilestone, value: holdMilestone }}
              onChange={(e) => {
                setHoldMilestoneID(e.id);
                setHoldMilestone(e.value);
                setHoldCompany(e.company);
              }}
            />
          </div>
          <div className="new-entry-group">
            <label className="ts-label">Status</label>
            <Select
              options={getSelect("status")}
              styles={customStyles}
              value={{ label: holdStatus, value: holdStatus }}
              onChange={(e) => {
                setHoldStatus(e.value);
              }}
            />
          </div>
          <div className="new-entry-group w-20">
            <label className="ts-label">Hours</label>

            <input
              value={holdHours}
              onChange={(e) => {
                // let str = e.target.value;
                // str.replace(/-(\d)/, "$1");

                setHoldHours(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !isDisabled) {
                  addTime();
                }
              }}
              className=" w-100 fancy-input"
              type="number"
            ></input>
          </div>
          <div className="w-20 justify-content-center d-flex align-items-end new-entry-group text-center">
            <button onClick={addTime} className="purp-btn new-entry-submit" disabled={isDisabled}>
              add
            </button>
          </div>
        </div>

        <h2 className="day-hours sub-header">
          Total {daySelected} Hours: {dayHours}
        </h2>
        <div className="tab-switcher-container">
          <div className="tab-switcher-head">
            <div
              onClick={() => setDaySelected("Monday")}
              className={`${daySelected === "Monday" ? "active-tab" : null} tab-head-item`}
            >
              Monday
            </div>
            <div
              onClick={() => setDaySelected("Tuesday")}
              className={`${daySelected === "Tuesday" ? "active-tab" : null} tab-head-item`}
            >
              Tuesday
            </div>
            <div
              onClick={() => setDaySelected("Wednesday")}
              className={`${daySelected === "Wednesday" ? "active-tab" : null} tab-head-item`}
            >
              Wednesday
            </div>
            <div
              onClick={() => setDaySelected("Thursday")}
              className={`${daySelected === "Thursday" ? "active-tab" : null} tab-head-item`}
            >
              Thursday
            </div>
            <div
              onClick={() => setDaySelected("Friday")}
              className={`${daySelected === "Friday" ? "active-tab" : null} tab-head-item`}
            >
              Friday
            </div>
            <div
              onClick={() => setDaySelected("Saturday")}
              className={`${daySelected === "Saturday" ? "active-tab" : null} tab-head-item`}
            >
              Saturday
            </div>
            <div
              onClick={() => setDaySelected("Sunday")}
              className={`${daySelected === "Sunday" ? "active-tab" : null} tab-head-item`}
            >
              Sunday
            </div>
            <div
              onClick={() => setDaySelected("Volunteer")}
              className={`${daySelected === "Volunteer" ? "active-tab" : null} tab-head-item`}
            >
              Volunteer
            </div>
          </div>

          <div className="tab-content-container">
            <div className="tab-switcher-content">
              <Table responsive="sm">
                <thead>
                  <tr>
                    <th style={{ width: "15%" }}>Day</th>
                    <th>Project</th>
                    <th>Milestone</th>
                    <th style={{ width: "15%" }}>Hours</th>

                    <th style={{ width: "15%" }}>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {times.map((time, index) => {
                    if (time.day === daySelected) {
                      return (
                        <tr className="white" key={index}>
                          <td>
                            <Select
                              onChange={(e) => {
                                if (e.value) {
                                  let newArr = [...times];
                                  newArr[index].day = e.value;
                                  setTimes(newArr);
                                }
                              }}
                              value={{
                                label: titleCase(time.day),
                                value: time.day,
                              }}
                              styles={customStyles}
                              options={selectDay}
                            />
                          </td>
                          <td>
                            <p>{time.project_name}</p>
                          </td>
                          <td>
                            <p>{time.milestone_name}</p>
                          </td>
                          <td valign="center">
                            <input
                              onChange={(e) => {
                                validateHours(parseFloat(e.target.value));
                                if (e.target.value > 0 && isQuarterHour(e.target.value)) {
                                  let newArr = [...times];
                                  newArr[index].hours = parseFloat(e.target.value);
                                  setTimes(newArr);
                                }
                              }}
                              onKeyPress={(e) => {
                                if (e.code === "Minus") {
                                  e.preventDefault();
                                }
                              }}
                              className="fancy-input"
                              type="number"
                              defaultValue={time.hours}
                            ></input>
                          </td>
                          <td>
                            <button
                              onClick={() => {
                                setTimes(times.filter((time, i) => i !== index));
                              }}
                              className=" edit-btn"
                            >
                              delete
                            </button>
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSheet;
