import React, { useEffect, useState } from "react";
import "./board.css";
import Table from "react-bootstrap/Table";
import projectService from "../../services/project.service";

function Board(props) {
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    projectService
      .getProjectsByCompany({ company: props.company })
      .then((res) => {
        setProjects(
          res.data.sort((a, b) => {
            var dateA = new Date(a.estimated_end_date);
            var dateB = new Date(b.estimated_end_date);
            if (dateA == "Invalid Date") {
              return 1;
            } else {
              return dateA - dateB;
            }
          })
        );
      });
    const interval = setInterval(() => {
      projectService
        .getProjectsByCompany({ company: props.company })
        .then((res) => {
          setProjects(res.data);
        });
    }, 43200000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`board ${props.company}-board`}>
      <img
        alt={`A logo for ${props.comapny}`}
        className={`${props.company}-logo`}
        src={require(`./${props.company}-logo.png`)}
      />
      <Table bordered className={`board-table ${props.company}-table`}>
        <thead>
          <tr>
            <th className={`${props.company}-thead`}>#</th>
            <th className={`${props.company}-thead`}>Project Name</th>
            <th className={`${props.company}-thead`}>Client</th>
            <th className={`${props.company}-thead`}>Status</th>
            <th className={`${props.company}-thead`}>Collaborators</th>
            <th className={`${props.company}-thead`}>Due</th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project, i) => {
            return (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{project.project_name}</td>
                <td>{project.client_name || "no client name"}</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: `<span class=${
                      project.status.replace(/\s/g, "") + " circle"
                    }>&#9679</span> <span class="t-black">${
                      project.status
                    }</span>`,
                  }}
                ></td>
                <td>
                  {project.users.map((user, i) => {
                    return i < project.users.length - 1
                      ? user.name + ", "
                      : user.name;
                  })}
                </td>
                <td>{project.estimated_end_date || "no date set"}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default Board;
