import axios from "axios";
import authHeader from "./auth-header";
const API = window.location.href.includes("local")
  ? "http://localhost:8080/api/auth/"
  : window.location.href.includes("proper")
  ? "https://omis.herokuapp.com/api/auth/"
  : window.location.href.includes("omisbk")
  ? "https://myomis-archive.herokuapp.com/api/auth/"
  : "https://myomis.herokuapp.com/api/auth/";

class AuthService {
  login(email, password) {
    return axios
      .post(API + "signin", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(API + "signup", {
      username,
      email,
      password,
    });
  }

  getCurrentUser() {
    try {
      return JSON.parse(localStorage.getItem("user"));
    } catch {
      localStorage.clear();
    }
  }
  updatePassword(data) {
    return axios.post(API + "update-pass", data, { headers: authHeader() });
  }
}

export default new AuthService();
