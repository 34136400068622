import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import requestService from "../../services/request.service";
import authService from "../../services/auth.service";
import { Table } from "react-bootstrap";
import Select from "react-select";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
const FadeIn = styled.div`
  animation: 0.5s ${keyframes`${fadeIn}`};
`;
const requestStatuses = [
  { label: "Unseen", value: "Unseen" },
  { label: "Seen", value: "Seen" },
  { label: "Fulfilled", value: "Fulfilled" },
];
function ViewRequest() {
  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "var(--tcMain)" : null,
        color: isFocused ? "white" : "#333333",
      };
    },
  };
  let user = authService.getCurrentUser().id;
  function requestStatusUpdate(e, i) {
    let tempArr = [...requests];
    tempArr[i].status = e.value;

    setRequests(tempArr);
    requestService.updateStatus({ request: tempArr[i] });
  }
  let [requests, setRequests] = useState([]);
  useEffect(() => {
    requestService.getAll({ uid: user }).then((res) => {
      let newArr = res.data.filter(
        (u) => u.status.toLowerCase() != "fulfilled"
      );
      setRequests(newArr);
    });
  }, []);
  return (
    <div className="flex">
      <Sidebar />
      <div className="request-container">
        <header className="header">View Requests</header>
        <FadeIn>
          <Table striped>
            <thead>
              <tr>
                <th>#</th>
                <th>user</th>
                <th>request</th>
                <th>company</th>
                <th>date requested</th>
                <th>Request Status</th>
              </tr>
            </thead>
            <tbody>
              {requests.map((request, i) => {
                if (request.status.toLowerCase() != "fulfilled") {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{request.requestee}</td>
                      <td>{request.request}</td>
                      <td>{request.company}</td>
                      <td>{request.createdAt.substring(0, 10)}</td>
                      <td>
                        <Select
                          value={{
                            label: request.status,
                            value: request.status,
                          }}
                          onChange={(e) => requestStatusUpdate(e, i)}
                          styles={customStyles}
                          options={requestStatuses}
                        />
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
        </FadeIn>
      </div>
    </div>
  );
}

export default ViewRequest;
