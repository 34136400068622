import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import authService from "../../services/auth.service";
import timeService from "../../services/time.service";
import Sidebar from "../../components/Sidebar";
function AdminSingle(props) {
  const [startTimes, setStartTimes] = useState([]);
  const [endTimes, setEndTimes] = useState([]);

  const uid = authService.getCurrentUser().id;

  useEffect(() => {
    timeService
      .adminSingle({ user: props.user, start: props.start, end: props.end })
      .then(function (res) {
        let tempStart = [];
        let tempEnd = [];
        res.data.forEach((u) => {
          u.week_ending === props.start ? tempStart.push(u) : tempEnd.push(u);
        });
        setStartTimes(tempStart);
        setEndTimes(tempEnd);
      });
  }, [props.user]);

  return (
    <div className="d-flex w-100">
      <div className="pad-2 w-100">
        <div className="start-times w-100">
          <div className="header-container">
            <header className="header">Detailed View</header>
            <button onClick={props.handleBack} className="mt-0 purp-btn">
              Back
            </button>
          </div>
          <h4 className="slight-fade">User: {props.user}</h4>

          <hr />
          <subheader className="week-ending-sub sub-header">
            Week Ending: {props.start}
          </subheader>
          <Table>
            <thead>
              <tr>
                <th>Day</th>
                <th>Project Name</th>
                <th>Milestone Name</th>
                <th>Company</th>
                <th>Hours</th>
              </tr>
            </thead>
            <tbody>
              {startTimes.map((time) => {
                return (
                  <tr>
                    <td>{time.day}</td>
                    <td>{time.project_name}</td>
                    <td>{time.milestone}</td>
                    <td>{time.company}</td>
                    <td>{time.hours}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className="end-times">
          <subheader className="week-ending-sub sub-header">
            Week Ending: {props.end}
          </subheader>
          {endTimes.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <th>Day</th>
                  <th>Project Name</th>
                  <th>Company</th>
                  <th>Hours</th>
                </tr>
              </thead>
              <tbody>
                {endTimes.map((time, index) => {
                  return (
                    <tr key={index}>
                      <td>{time.day}</td>
                      <td>{time.project_name}</td>
                      <td>{time.company}</td>
                      <td>{time.hours}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default AdminSingle;
