import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import CDInput from "../../components/CDInput";
import Sidebar from "../../components/Sidebar";
import { sortObjArray } from "../../services/helper";
import projectService from "../../services/project.service";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
const Fade = styled.div`
  animation: 0.5s ${keyframes`${fadeIn}`};
`;
function ClientDirectory() {
  const [clientList, setClientList] = useState([]);
  const [currentlyEditing, setCurrentlyEditing] = useState({});
  const [autoSave, setAutoSave] = useState(false);
  useEffect(() => {
    projectService.getClients().then((res) => {
      // add on isEditable property to incoming object
      let dRes = res.data.map((e) => ({ ...e, isEditable: false }));
      dRes = sortObjArray({
        array: dRes,
        sortBy: "alphabetical",
        arrayType: "client",
      });
      setClientList(dRes);
    });
  }, []);
  const handleChange = (e) => {
    const field = e.target.getAttribute("name");
    const val = e.target.value;
    let newList = [...clientList];
    let index = newList.findIndex((e) => e.isEditable == true);
    let newElement = { ...newList[index] };
    if (field != "phone") {
      newElement[field] = val;
    } else {
      // test to see if 3 sets of digits and format them correctly
      let regex = /^[^\(]?([0-9]{3}).*([0-9]{3}).*([0-9]{4})/gm;
      if (val.match(regex)) {
        let newVal = val.replace(regex, "($1) $2-$3");
        newElement[field] = newVal;
      } else {
        newElement[field] = val;
      }
    }
    newList[index] = newElement;
    setCurrentlyEditing(newElement);
    setClientList(newList);
  };
  // client side editing
  const editClient = (id) => {
    let newList = [...clientList];
    updateClient();
    setCurrentlyEditing({});
    newList.forEach((c) => {
      c.isEditable = false;
    });
    newList.forEach((c) => {
      if (c.id == id) {
        c.isEditable = true;
        setCurrentlyEditing(c);
      }
    });

    setClientList(newList);
  };
  // not yet implemented
  const handleDelete = (id) => {
    projectService.deleteClient({ id }).then(() => {
      let newList = [...clientList];
      newList.filter((client) => client.id != id);
      setClientList(newList);
    });
  };
  useEffect(() => {
    if (currentlyEditing.hasOwnProperty("name")) {
      updateClient(currentlyEditing);
    }
  }, [currentlyEditing.id]);
  const updateClient = () => {
    projectService.updateClient({ client: currentlyEditing }).then(() => {});
  };
  return (
    <div className="flex">
      <Sidebar />
      <div className="inner-container">
        <header>
          <h1 className="header">Client Directory</h1>
        </header>
        <div className="my-5 table-container">
          <Table striped bordered>
            <thead>
              <tr>
                <th>Contact Name</th>
                <th>Email</th>
                <th>Client Address</th>
                <th>Client Phone</th>
                <th>Company</th>
              </tr>
            </thead>
            <tbody>
              {clientList.map((client) => {
                return !client.isEditable ? (
                  <tr key={client.id} onClick={() => editClient(client.id)}>
                    <td>{client.name}</td>
                    <td>{client.email}</td>
                    <td>{client.address}</td>
                    <td>{client.phone}</td>
                    <td>{client.company}</td>
                  </tr>
                ) : (
                  <tr key={client.id}>
                    <CDInput
                      name="name"
                      change={handleChange}
                      val={client.name}
                    />
                    <CDInput
                      name="email"
                      change={handleChange}
                      val={client.email}
                    />
                    <CDInput
                      name="address"
                      change={handleChange}
                      val={client.address}
                    />
                    <CDInput
                      name="phone"
                      change={handleChange}
                      val={client.phone}
                    />
                    <CDInput
                      name="company"
                      change={handleChange}
                      val={client.company}
                    />
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default ClientDirectory;
