import React from "react";
function ProfileGroup(props) {
  let socials = [
    "twitter",
    "facebook-f",
    "tiktok",
    "instagram",
    "linkedin-in",
    "twitch",
  ];
  return (
    <div className="prof-input-grp">
      <span
        className={
          socials.includes(props.icon)
            ? `fab fa-${props.icon} prof-icon`
            : `fa fa-${props.icon} prof-icon`
        }
      />
      {props.isLink && props.value ? (
        <a className="fancy-input w-100 text-black" href={`${props.value}`}>
          {props.value}
        </a>
      ) : (
        <input
          disabled={props.inputDisabled}
          onChange={props.onChange}
          placeholder={props.placeholder}
          type="text"
          name={props.name}
          value={props.value}
          className="fancy-input"
        />
      )}
    </div>
  );
}

export default ProfileGroup;
