import React from "react";
import FontAwesome from "react-fontawesome";
import { Link } from "react-router-dom";
function NavItem(props) {
  return (
    <Link to={props.link}>
      <div className="nav-item">
        <FontAwesome
          className="nav-icon"
          name={props.icon}
          size="2x"
          style={{ textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)" }}
        />
        {props.header}
        {props.notification ? (
          <span className="nav-notification">{props.notification}</span>
        ) : null}
      </div>
    </Link>
  );
}

export default NavItem;
