import React, { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getSelect } from "../services/helper";
import userService from "../services/user.service";
import Select from "react-select";
import authService from "../services/auth.service";
function User(props) {
  const necessary = ["user, admin"];
  const companies = getSelect("company").concat([
    { label: "user", value: "user" },
    { label: "moderator", value: "moderator" },
    { label: "admin", value: "admin" },
  ]);
  const isAdmin = authService.getCurrentUser().roles.includes("ROLE_ADMIN")
    ? true
    : false;
  const navigate = useNavigate();
  const [roles, setRoles] = useState(
    props.roles.map((e) => {
      return { label: e.name, value: e.name };
    })
  );

  const handleClick = () => {
    navigate(`/user/${props.id}`);
  };
  const handleChange = (e) => {
    if (e.length > 0) {
      setRoles(e);
      userService
        .changeRoles({ user: props.id, roleList: e })
        .then((res) => {});
    }
  };
  return (
    <div className="user-card">
      <Card style={{ width: "18rem" }}>
        <Card.Body className="text-center">
          <div className="text-center d-flex justify-content-center mb-3">
            <Card.Img
              style={{
                width: "50%",
                background: props.background,
                borderRadius: "100%",
              }}
              src={props.image}
            />
          </div>
          <Card.Title className="text-center">
            <strong>{props.name}</strong>
          </Card.Title>
          <Card.Text>
            {isAdmin ? (
              <Select
                onChange={handleChange}
                isMulti={true}
                options={companies}
                value={roles}
              />
            ) : null}
          </Card.Text>

          <button onClick={handleClick} className="mt-5 h6 purp-btn">
            View Profile
          </button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default User;
