import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Table from "react-bootstrap/Table";
import reportService from "../../services/report.service";
import authService from "../../services/auth.service";
import projectService from "../../services/project.service";
import Select from "react-select";
import { ExportCSV } from "../../services/ExcelExporter";
import { getSelect, titleCase } from "../../services/helper";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "var(--tcMain)" : null,
      color: isFocused ? "white" : "#333333",
    };
  },
};

function Reports() {
  const [reports, setReports] = useState([]);
  const [projects, setProjects] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  let userId = authService.getCurrentUser().id;
  const [totalTime, setTotalTime] = useState(0);
  const [archTotalTime, setArchTotalTime] = useState(0);
  const selectCompanies = getSelect("company");
  const [company, setCompany] = useState("");
  const [filteredArr, setFilteredArr] = useState([]);
  const [archived, setArchived] = useState([]);
  const [viewArchived, setViewArchived] = useState(false);
  const [companyHours, setCompanyHours] = useState(0);
  const [show, setShow] = useState(false);
  const [currentDate, setCurrentDate] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    // get all projects
    projectService.getProjects({ id: userId }).then((res) => {
      let finalArr = [];
      res.data.forEach((item, i) => {
        finalArr.push({
          label: item.project_name,
          value: item.id,
        });
      });
      setProjects(finalArr);
    });
  }, []);

  useEffect(() => {
    // for companies
    setCurrentDate(new Date());
    if (!selectedProject) {
      return;
    }
    reportService.getReports(selectedProject).then((res) => {
      let arr = [];
      let archivedArr = [];
      let archHours = 0;
      let totalHours = 0;
      res.data.forEach((data, i) => {
        if (data.is_archived) {
          archivedArr[i] = {
            id: data.id,
            company: data.company,
            weekEnding: data.week_ending,
            user: data.user_name,
            milestone: data.milestone,
            hours: data.hours,
            isArchived: data.is_archived,
          };
          archHours += data.hours;
        } else {
          arr[i] = {
            id: data.id,
            company: data.company,
            weekEnding: data.week_ending,
            user: data.user_name,
            milestone: data.milestone,
            hours: data.hours,
            isArchived: data.is_archived,
          };
          totalHours += data.hours;
        }
      });
      setReports(arr);
      setArchived(archivedArr);
      setTotalTime(totalHours);
      setArchTotalTime(archHours);
    });
  }, [selectedProject]);

  // For archived Projects
  useEffect(() => {
    if (viewArchived) {
      filterProject(archived);
      return;
    }
    filterProject(reports);
  }, [selectedProject, viewArchived, company, reports]);

  const filterProject = (arr) => {
    let newHours = 0;
    const newArr = arr.filter((elem) => {
      if (elem) {
        if (elem.company == company) {
          newHours += elem.hours;
          return 1;
        }
      } else return 0;
    });
    setCompanyHours(newHours);
    setFilteredArr(newArr);
  };

  const handleArchive = () => {
    let timesheetIds = reports.map((item) => item.id);
    handleClose();
    reportService
      .archiveSheets(company, selectedProject, timesheetIds)
      .then((res) => console.log(res.data));
  };

  return (
    <div className="d-flex">
      <Sidebar />
      <div className="content-container">
        <div className="w-11 d-flex justify-content-center">
          <div className="w-75">
            <h1 className="header">Project Reports</h1>
            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Body>Would you like to archive this report?</Modal.Body>
              <Modal.Footer>
                <Button variant="dark" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="dark" onClick={handleArchive}>
                  Yes, Archive!
                </Button>
              </Modal.Footer>
            </Modal>
            <h3>
              Total Hours for : {viewArchived ? archTotalTime : totalTime}
            </h3>

            <div className="report-filters">
              Select Project <span className="required">*</span>
              <div className="form-group">
                <Select
                  id="label"
                  aria-label="label-select"
                  styles={customStyles}
                  onChange={(e) => {
                    setSelectedProject(e.label);
                  }}
                  options={projects}
                />
              </div>
              Select Company <span className="required">*</span>
              <div className="form-group">
                <Select
                  id="company"
                  aria-label="company-select"
                  styles={customStyles}
                  onChange={(e) => {
                    setCompany(e.value);
                  }}
                  options={selectCompanies}
                />
              </div>
            </div>
            {/* For Export and Archive Buttons */}
            <div className="btn-holder">
              {selectedProject && (
                <ExportCSV
                  csvData={filteredArr}
                  fileName={`Project_report_for_${selectedProject}_${company}_${
                    currentDate.getMonth() + 1
                  }_${currentDate.getDate()}_${currentDate.getFullYear()}`}
                />
              )}
              <button
                className="mt-0 purp-btn ts-save"
                onClick={filteredArr.length ? handleShow : null}
              >
                Archive
              </button>
            </div>
            <br></br>
            <input
              type="checkbox"
              id="viewArchived"
              onChange={(e) => setViewArchived(e.target.checked)}
            ></input>
            <label htmlFor="viewArchived">View Archived</label>
            <div>
              <h3>
                Total hours for {company}: {companyHours}
              </h3>
            </div>
            <br></br>

            <Table
              striped
              bordered
              responsive-md
              hover
              style={{ marginTop: 75 }}
              id="myTable"
            >
              <thead>
                <tr className="bg-dark white" style={{ color: "white" }}>
                  <th>#</th>
                  <th>Week Ending</th>
                  <th>Company</th>
                  <th>Milestones</th>
                  <th>Employee</th>
                  <th>Hours</th>
                </tr>
              </thead>
              <tbody>
                {filteredArr.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{item.weekEnding}</td>
                      <td>{item.company}</td>
                      <td>{item.milestone}</td>
                      <td>{item.user}</td>
                      <td>{item.hours}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reports;
