import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Table from "react-bootstrap/Table";
import reportService from "../../services/report.service";
import Select from "react-select";
import { ExportCSV } from "../../services/ExcelExporter";
import { getSelect, titleCase } from "../../services/helper";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

function BPS() {
  const [reports, setReports] = useState([]);
  const [totalTime, setTotalTime] = useState(0);
  const [archTotalTime, setArchTotalTime] = useState(0);
  const selectCompanies = getSelect("company");
  const [company, setCompany] = useState("");
  const [filteredArr, setFilteredArr] = useState([]);
  const [archived, setArchived] = useState([]);
  const [viewArchived, setViewArchived] = useState(false);
  const [companyHours, setCompanyHours] = useState(0);
  const [show, setShow] = useState(false);
  const [currentDate, setCurrentDate] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setCurrentDate(new Date());
    reportService.bpsReport().then((res) => {
      // console.log("bps report" + JSON.stringify(res.data));

      let arr = [];
      let archivedArr = [];
      let archHours = 0;
      let totalHours = 0;
      res.data.forEach((data, i) => {
        if (data.is_archived) {
          // console.log(`${data.company} is archived`);
          archivedArr[i] = {
            id: data.id,
            company: data.company,
            weekEnding: data.week_ending,
            user: data.user_name,
            milestone: data.milestone,
            hours: data.hours,
            isArchived: data.is_archived,
          };
          archHours += data.hours;
        } else {
          arr[i] = {
            id: data.id,
            company: data.company,
            weekEnding: data.week_ending,
            user: data.user_name,
            milestone: data.milestone,
            hours: data.hours,
            isArchived: data.is_archived,
          };
          totalHours += data.hours;
        }
        //console.log(arr[i].company, arr[i].user);
      });
      setReports(arr);
      setArchived(archivedArr);
      setTotalTime(totalHours);
      setArchTotalTime(archHours);
    });
  }, []);

  useEffect(() => {
    // console.log(company);
    if (!viewArchived) {
      filterCompany(reports);
      return;
    }
    filterCompany(archived);
  }, [company, viewArchived]);

  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "var(--tcMain)" : null,
        color: isFocused ? "white" : "#333333",
      };
    },
  };

  const filterCompany = (arr) => {
    let newArr = [...arr];
    let newHours = 0;

    // eslint-disable-next-line array-callback-return
    newArr = newArr.filter((elem) => {
      if (elem) {
        if (elem.company == company) {
          newHours += elem.hours;
          return 1;
        }
      } else return 0;
    });
    setCompanyHours(newHours);
    setFilteredArr(newArr);
    // console.log(
    //   "filtering for " + company + ": " + JSON.stringify(filteredArr)
    // );
  };

  const handleArchive = () => {
    let newArr = reports.map((item) => item.id);
    handleClose();
    reportService
      .bpsArchiveSheets(newArr, company)
      .then((res) => console.log(res.data));
  };

  return (
    <div className="d-flex">
      <Sidebar />
      <div className="content-container">
        <div className="w-100 d-flex justify-content-center ">
          <div className="w-75">
            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Body>Would you like to archive this report?</Modal.Body>
              <Modal.Footer>
                <Button variant="dark" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="dark" onClick={handleArchive}>
                  Yes, Archive!
                </Button>
              </Modal.Footer>
            </Modal>

            <div className="btn-holder">
              {company && (
                <ExportCSV
                  csvData={filteredArr}
                  fileName={`${currentDate.getMonth()}-${currentDate.getDate()}-${currentDate.getFullYear()}_BPS_report_for_${company}`}
                />
              )}
              <button
                className="mt-0 purp-btn ts-save"
                onClick={filteredArr.length ? handleShow : null}
              >
                Archive
              </button>
            </div>
            <h1 className="header">BPS Projects</h1>
            <p className="bold">
              Total Hours: {viewArchived ? archTotalTime : totalTime}
            </p>
            <br />
            <input
              type="checkbox"
              id="viewArchived"
              onChange={(e) => setViewArchived(e.target.checked)}
            ></input>
            <label htmlFor="viewArchived">View Archived</label>

            <form className="add-new-form">
              <div className="form-group">
                <label>
                  Select Company <span className="required">*</span>
                </label>
                <Select
                  id="company"
                  aria-label="company-select"
                  styles={customStyles}
                  onChange={(e) => {
                    setCompany(e.value);
                  }}
                  options={selectCompanies}
                />
              </div>
            </form>
            <div>
              <h3>
                Total hours for {company}: {companyHours}
              </h3>
            </div>
            <Table
              striped
              bordered
              responsive-md
              hover
              style={{ marginTop: 75 }}
              id="myTable"
            >
              <thead>
                <tr className="bg-dark white" style={{ color: "white" }}>
                  <th>#</th>
                  <th>Week Ending</th>
                  <th>Company</th>
                  <th>Milestones</th>
                  <th>Employee</th>
                  <th>Hours</th>
                </tr>
              </thead>
              <tbody>
                {filteredArr.map((item, i) => {
                  // console.log("item" + JSON.stringify(reports[item]));
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{item.weekEnding}</td>
                      <td>{item.company}</td>
                      <td>{item.milestone}</td>
                      <td>{item.user}</td>
                      <td>{item.hours}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BPS;
