import React, { useState, useEffect } from "react";
import { Alert, Table } from "react-bootstrap";
import projectService from "../../services/project.service";
import userService from "../../services/user.service";
import FontAwesome from "react-fontawesome";
import Select from "react-select";
import { useParams } from "react-router-dom";
import MilestonePopup from "../../components/MilestonePopup";
import MilestoneInput from "../../components/MilestoneInput";
import {
  sortObjArray,
  getSelect,
  titleCase,
  milestoneTemplates,
} from "../../services/helper";
import Th from "../../components/Th";
import Loading from "../../components/Loading";
import authService from "../../services/auth.service";
import notificationService from "../../services/notification.service";
function MileStones() {
  const templates = getSelect("templates");
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [contributors, setContributors] = useState([]);
  const [formFields, setFormFields] = useState({});
  const [primaryCompany, setPrimaryCompany] = useState("");
  const [milestones, setMilestones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [template, setTemplate] = useState(templates[0]);
  const [currentMilestone, setCurrentMilestone] = useState({});
  const [alphUp, setAlphUp] = useState(false);
  const [prioUp, setPrioUp] = useState(false);
  const [statusUp, setStatusUp] = useState(false);
  const [bulk, setBulk] = useState(false);
  const [err, setErr] = useState("");
  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "var(--tcMain)" : null,
        border: isFocused ? null : null,
        color: isFocused ? "white" : "#333333",
      };
    },
  };
  const templateObj = milestoneTemplates();
  const handleSort = (which) => {
    if (which == "alphabetical") {
      setMilestones(
        sortObjArray({
          array: milestones,
          sortBy: "alphabetical",
          direction: alphUp,
          arrayType: "milestone",
        })
      );
      setAlphUp(!alphUp);
    } else if (which === "status") {
      setMilestones(
        sortObjArray({
          array: milestones,
          sortBy: "status",
          arrayType: "project",
          direction: statusUp,
        })
      );
      setStatusUp(!statusUp);
    } else if (which === "priority") {
      setMilestones(
        sortObjArray({
          array: milestones,
          sortBy: "priority",
          direction: prioUp,
        })
      );
      setPrioUp(!prioUp);
    }
  };
  const handleClose = () => {
    setShow(false);
    setCurrentMilestone({});
  };
  const handleShow = () => setShow(true);

  const handleSelect = (e, id, val) => {
    let tempMilestones = [...milestones];

    let finalId = bulk
      ? tempMilestones.map((a) => {
          console.log(a.status);
          if (a.status.value.toLowerCase() != "complete") {
            return a.id;
          }
        })
      : tempMilestones
          .map((a) => {
            if (a.id == id) {
              return a.id;
            }
          })
          .filter((a) => a != undefined);

    if (val == "assigned_to") {
      projectService
        .updateMs({ msId: finalId, field: val, newValue: e })
        .then((res) => {
          console.log("done");
        });
    } else if (val == "due_date") {
      projectService
        .updateMs({
          msId: finalId,
          field: val,
          newValue: e.target.value,
        })
        .then(() => {
          console.log("done");
        });
    } else {
      projectService
        .updateMs({ msId: finalId, field: val, newValue: e.value })
        .then((res) => {});
    }

    tempMilestones.forEach((ms) => {
      if (finalId.includes(ms.id)) {
        if (val == "assigned_to") {
          ms[val] = e;
        } else if (val == "due_date") {
          ms[val] = e.target.value;
        } else {
          ms[val] = { label: e.value, value: e.value };
        }
      }
    });
    setMilestones(tempMilestones);
  };
  const deleteMs = (msId) => {
    projectService.deleteMs({ msId: msId, pid: id }).then(() => {
      let tempMilestones = [...milestones];
      tempMilestones = tempMilestones.filter((i) => {
        return i.id != msId;
      });
      setMilestones(tempMilestones);
    });
  };
  const addMilestone = (msInput, date) => {
    setErr("");
    if (msInput != "") {
      if (date) {
        projectService
          .newMilestone({
            milestone_name: msInput,
            due_date: date,
            company: primaryCompany,
            project_id: id,
          })
          .then((newEntry) => {
            let toAdd = newEntry.data;
            toAdd.company = { label: toAdd.company, value: toAdd.company };
            setMilestones([...milestones, toAdd]);
          });
      } else {
        setErr("Please enter an (estimated) due date");
      }
    } else {
      setErr("Please name your milestone");
    }
  };

  const generateTemplate = () => {
    let whichTemplate = template.toLowerCase();
    let arr = templateObj[whichTemplate].map((e) => {
      return { milestone_name: e, project_id: id };
    });
    projectService.bulkMilestone({ milestones: arr }).then((res) => {
      setMilestones((old) => [...old, ...res.data]);
    });
  };
  const handlePoke = (msId) => {
    let userList = milestones
      .find((e) => e.id == msId)
      .assigned_to.map((user) => user.value);
    console.log(userList);
    if (userList.length > 0) {
      notificationService.poke({ msId, users: userList });
    }
  };
  const handleModalSubmit = (data) => {
    let old = [...milestones];
    old.forEach((u) => {
      if (u.id === data.id) {
        u.milestone_name = data.title;

        u.description = data.description;
      }
    });
    projectService
      .updateMs({
        msId: [data.id],
        field: "milestone_name",
        newValue: data.title,
      })
      .then(() => {
        projectService
          .updateMs({
            msId: [data.id],
            field: "description",
            newValue: data.description,
          })
          .then(() => {});
      });
    setMilestones(old);

    setShow(false);
  };
  useEffect(() => {
    projectService.getMilestones({ pid: id }).then((res) => {
      let newArr = [];
      res.data.forEach((entry) => {
        let at = entry.users.map((u) => {
          return { label: u.name, value: u.name };
        });
        newArr.push({
          milestone_name: entry.milestone_name,
          priority: { label: entry.priority, value: entry.priority },
          status: { label: entry.status, value: entry.status },
          assigned_to: at,
          company: {
            label: entry.company || formFields.company,
            value: entry.company || formFields.company,
          },
          description: entry.description,
          due_date: entry.due_date,
          id: entry.id,
        });
      });
      setMilestones(newArr);
      setLoading(false);
    });
    projectService.getProjectById(id).then((res) => {
      // this var used to be array res.data.project[0];
      let project = res.data.project;
      
      let tempContrib = [];
      
      project.users.forEach((user) => {
        tempContrib.push({ label: user.name, value: user.name });
      });
      
      let { id, company, project_name } = project;
      setFormFields({
        id,
        company,
        project_name,
      });
      setPrimaryCompany(company);
      setContributors(tempContrib);
    });
    // projectService.getProjectById(id);
  }, []);

  useEffect(() => {
    let temp = [...milestones];
    temp.forEach((ms) => {
      if (!ms.company.value) {
        ms.company.value = formFields.company;
        ms.company.label = formFields.company;
      }
    });
    setMilestones(temp);
  }, [formFields]);

  return (
    <div className="flex">
      <div className="pt-5 milestones-container">
        <header className=" ms-header">
          <h2 className="sub-header">Milestones</h2>
        </header>
        {err ? (
          <Alert className="mt-2 w-25" variant="danger">
            {err}
          </Alert>
        ) : null}
        <div className="create-milestone-container">
          <MilestonePopup
            handlePoke={handlePoke}
            handleClose={handleClose}
            handleShow={handleShow}
            show={show}
            title={currentMilestone.title}
            status={
              currentMilestone.status ? currentMilestone.status.label : ""
            }
            priority={
              currentMilestone.priority ? currentMilestone.priority.label : ""
            }
            contributor={
              currentMilestone.contributor
                ? currentMilestone.contributor.label
                : ""
            }
            id={currentMilestone.id}
            description={currentMilestone.description}
            handleSubmit={handleModalSubmit}
            buttonText="save"
          />
          <div className="input-group  create-ms-input-group">
            <label className="ts-label">Create a new milestone</label>
            <MilestoneInput addMilestone={addMilestone} />
          </div>
          <div className="input-group create-ms-input-group">
            <label className="ts-label">Generate a Template</label>
            <Select
              styles={customStyles}
              onChange={(e) => setTemplate(e.value)}
              className="w-75"
              options={templates}
            />
            <button onClick={generateTemplate} className="mt-2 ms-btn purp-btn">
              generate
            </button>
          </div>
        </div>
        <label>Bulk Edit</label>
        <br />
        <input onChange={() => setBulk(!bulk)} type="checkbox"></input>
        <div className="table-container">
          {loading ? (
            <Loading />
          ) : (
            <Table responsive="md" className="milestone-table">
              <thead>
                <tr>
                  <th className="small-theader"></th>
                  <Th
                    className="milestone-head"
                    name="Milestone"
                    handleSorter={() => handleSort("alphabetical")}
                  />

                  <Th
                    name="Priority"
                    handleSorter={() => handleSort("priority")}
                  />
                  <Th name="Status" handleSorter={() => handleSort("status")} />

                  <th style={{ width: "350px", minWidth: "350px" }}>
                    Contributor
                  </th>
                  <th style={{ minWidth: "150px" }}>Company</th>

                  <th>Due</th>
                  {authService.getCurrentUser().roles.includes("ROLE_ADMIN") ? (
                    <th>Delete</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {milestones.map((u, i) => {
                  if (u.status.value != "Complete") {
                    return (
                      <tr key={u.id}>
                        <td
                          className="pointer"
                          onClick={() => {
                            setCurrentMilestone({
                              title: u.milestone_name,
                              priority: u.priority,
                              status: u.status,
                              contributor: u.assigned_to,
                              description: u.description,
                              id: u.id,
                            });
                            setShow(true);
                          }}
                        >
                          <FontAwesome
                            className="nav-icon"
                            name="edit"
                            title="Edit Milestone"
                            style={{ textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)" }}
                          />
                        </td>
                        <td>{u.milestone_name}</td>
                        <td>
                          <Select
                            styles={customStyles}
                            className="milestone-select"
                            onChange={(e) => handleSelect(e, u.id, "priority")}
                            options={getSelect("priority")}
                            value={u.priority}
                          />
                        </td>
                        <td>
                          <Select
                            styles={customStyles}
                            className="milestone-select"
                            onChange={(e) => {
                              handleSelect(e, u.id, "status");
                            }}
                            options={getSelect("status")}
                            value={u.status}
                          />
                        </td>
                        <td>
                          <Select
                            styles={customStyles}
                            className="milestone-select"
                            value={u.assigned_to}
                            isMulti
                            onChange={(e) =>
                              handleSelect(e, u.id, "assigned_to")
                            }
                            options={contributors}
                          />
                        </td>
                        <td>
                          <Select
                            styles={customStyles}
                            className="milestone-select"
                            value={u.company}
                            onChange={(e) => handleSelect(e, u.id, "company")}
                            options={getSelect("company")}
                          />
                        </td>

                        <td>
                          {" "}
                          <input
                            value={u.due_date}
                            onChange={(e) => {
                              handleSelect(e, u.id, "due_date");
                              // let newDate = [...milestones];
                              // newDate[i].due_date = e.target.value;
                              // setMilestones(newDate);
                            }}
                            type="date"
                            className="fancy-input"
                          ></input>
                        </td>
                        {authService
                          .getCurrentUser()
                          .roles.includes("ROLE_ADMIN") ? (
                          <td className="center">
                            <button
                              onClick={() => deleteMs(u.id)}
                              msId={u.id}
                              associate={u.milestone_name}
                              className="edit-btn"
                            >
                              delete
                            </button>
                          </td>
                        ) : null}
                      </tr>
                    );
                  }
                })}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </div>
  );
}

export default MileStones;
