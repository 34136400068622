import React, {useState, useEffect} from 'react'
import authService from '../services/auth.service'
import {useNavigate} from "react-router-dom"
function Logout() {
    const navigate = useNavigate()
    useEffect(() => {
        authService.logout()
        setTimeout(() => {
            navigate("/login")
        },2000)

        
    }, [])
    
  return (
    <div className="logout component"></div>
  )
}

export default Logout