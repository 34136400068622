import axios from "axios";
import authHeader from "./auth-header";

const API_URL = window.location.href.includes("local")
  ? "http://localhost:8080/api/"
  : window.location.href.includes("proper")
  ? "https://omis.herokuapp.com/api/"
  : window.location.href.includes("omisbk")
  ? "https://myomis-archive.herokuapp.com/api/"
  : "https://myomis.herokuapp.com/api/";
class ReportService {
  milestoneByCompany(user) {
    return axios.post(API_URL + "/reports/ms-by-company", user, {
      headers: authHeader(),
    });
  }
  bpsReport() {
    return axios.post(
      API_URL + "reports/bps",
      {},
      {
        headers: authHeader(),
      }
    );
  }

  getReports(projectName) {
    if (projectName) {
      return axios.get(API_URL + "reports/" + projectName, {
        headers: authHeader(),
      });
    } else {
      return null;
    }
  }

  archiveSheets(company, projectName, timesheetIds) {
    const data = { company, projectName, timesheetIds };

    return axios.post(API_URL + "reports/archive", data, {
      headers: authHeader(),
    });
  }

  bpsArchiveSheets(ids, company) {
    const data = { ids, company };

    return axios.post(API_URL + "reports/bps-archive", data, {
      headers: authHeader(),
    });
  }
}
export default new ReportService();
